import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-bootstrap"
import Slider from "react-slick"
import { useStaticQuery, graphql } from "gatsby"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import CarSlideItem from "../../molecules/blocks/car-slide-item"
import NextArrow from "../../../images/icons/next-arrow.inline.svg";
import PrevArrow from "../../../images/icons/prev-arrow.inline.svg";
import SectionTitle from "../../molecules/blocks/section-title"
import CircleSvg from "../../../images/Ellipse39.inline.svg"
import CircleSvg2 from "../../../images/Ellipse40.inline.svg"
import CircleSvg3 from "../../../images/Ellipse41.inline.svg"
import CircleSvg4 from "../../../images/Ellipse42.inline.svg"

const CarSliderSection = () => {

  const data = useStaticQuery(graphql`
    {
      allContentfulCars {
        edges {
          node {
            id
            title
            image {
              url
            }
            moc
            pojemnoSilnika
            przebieg
            rokProdukcji
            silnik
          }
        }
      }
    }
  `);

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }

  return (
    <Wrapper id={`cars`}>
      <Container>
        <Row>
          <Col md={8} className={`ml-auto mr-auto`} style={{marginBottom: "120px"}}>
            <AbsoluteCircle className={`circle-2`}>
              <CircleSvg2 />
            </AbsoluteCircle>
            <AbsoluteCircle className={`circle-3`}>
              <CircleSvg3 />
            </AbsoluteCircle>
            <SectionTitle title={`Znajdź idealne auto! Oto zamówienia, które zrealizowaliśmy ostatnio dla naszych klientów `} description={`Zrealizowane zamówienia`}/>
          </Col>
        </Row>
        <Slider {...settings}>
          {data.allContentfulCars.edges.map((car, key) =>
            <CarSlideItem data={car} key={key}/>
          )}
        </Slider>
      </Container>
    </Wrapper>
  )
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + ' d-none d-sm-block'}
      style={{...style, display: "block", width: "46px", right: "-56px"}}
      onClick={onClick}
    >
      <NextArrow />
    </div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;

  return (
    <div
      className={className + ' d-none d-sm-block'}
      style={{...style, display: "block", width: "46px", left: "-56px"}}
      onClick={onClick}
    >
      <PrevArrow />
    </div>
  );
}

const Wrapper = styled.div``;

const AbsoluteCircle = styled.div`
  position: absolute;

  &.circle-2{
    top: -90px;
    right: 30%;
    width: 29px;
  }

  &.circle-3{
    top: -75px;
    right: 35%;
    width: 65px;
  }
`;

const cars = [
  {
    title: "Opel Insignia Elite 2.0 CDTI Bi-Turbo Automat 4x4 Sports Tourer",
    fileName: "car-1",
    year: "2018",
    power: "210 KM",
    przebieg: "93 000 km",
    pojemnosc: "1 956 cm3",
    silnik: "Diesel"
  },
  {
    title: "Audi A6 2.0 TDI S-tronic quattro S-line Limusine",
    fileName: "general",
    year: "2017",
    power: "190 KM",
    przebieg: "77 000 km",
    pojemnosc: "1 968cm3",
    silnik: "Diesel"
  },
  {
    title: "Mazda CX-3 SkyPASSION 2.0 i-ELOOP 4x4 Automat",
    fileName: "car-2",
    year: "2018",
    power: "150 KM",
    przebieg: "17 800 km",
    pojemnosc: "1 998 cm3",
    silnik: "Benzyna"
  },
  {
    title: "Jeep Renegade Limited 1.4 MultiAir",
    fileName: "general",
    year: "2018",
    power: "140 KM",
    przebieg: "36 000 km",
    pojemnosc: "1 368 cm3",
    silnik: "benzyna"
  },
  {
    title: "VW Passat Highline R-line 2.0 TSI DSG 4Motion Variant",
    fileName: "car-4",
    year: "2017",
    power: "280 KM",
    przebieg: "160 000 km",
    pojemnosc: "1 984 cm3",
    silnik: "Benzyna"
  },
  {
    title: "Volkswagen Passat Alltrack 2.0 TDI Bi-Turbo 4Motion DSG",
    fileName: "car-5",
    year: "2016",
    power: "240 KM",
    przebieg: "89 000 km",
    pojemnosc: "1 968 cm3",
    silnik: "Diesel"
  },
  {
    title: "Volvo XC60 Summum T5 Geartronic AWD Drive-E",
    fileName: "car-3",
    year: "2017",
    power: "245 KM",
    przebieg: "104 000 km",
    pojemnosc: "1 969 cm3",
    silnik: "Benzyna"
  },
  {
    title: "Skoda Kodiaq Style 2.0 TDI DSG 4x4",
    fileName: "general",
    year: "2018",
    power: "190 KM",
    przebieg: "95 000 km",
    pojemnosc: "1 968 cm3",
    silnik: "Diesel"
  }
]

export default CarSliderSection
