import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import BlockWithAction from "../../molecules/blocks/block-with-action"
import HeaderImage from "../../molecules/blocks/header-image"

const HeaderSection = () => (
  <Wrapper>
    <Container>
      <Row>
        <Col md={5}>
          <BlockWithAction/>
        </Col>
        <Col md={7}>
          <HeaderImage />
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.section`
    min-height: 100vh;
    padding-top: 130px;
    display: flex;
    align-items: center;
    margin-bottom: 140px;
    
    @media screen and (min-width: 768px) {
      padding-top: 75px;
    }
`

export default HeaderSection
