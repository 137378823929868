import React from "react";
import { Link } from "gatsby"
import styled from "styled-components";

const OrangeButton = ({text, to}) => (
  <Button to={to}>
    {text}
  </Button>
);

const Button = styled(Link)`
  background: linear-gradient(90deg, #FAB501 5.31%, #FF6231 96.56%);
  border-radius: 25px;
  display: inline-block;
  width: fit-content;
  padding: 13px 70px;
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
  transition: all 0.4s ease-in-out;
  background-size: 150% 1px;
  
  &:hover{
    background-position: -100px; 
    text-decoration: none;
    color: white;
  }
`

OrangeButton.defaultProps = {
  to: "/",
  text: "Call to action"
}

export default OrangeButton;
