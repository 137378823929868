import React from "react"
import styled from "styled-components";

const CircleWithIcon = ({Icon}) => (
  <Wrapper>
    <Icon />
  </Wrapper>
);

const Wrapper = styled.div`
  border-radius: 100px;
  background: linear-gradient(90deg, #FAB501 5.31%, #FF6231 96.56%);
  width: 40px;
  height: 40px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
`;

export default CircleWithIcon;
