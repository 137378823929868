import React from "react";
import { Col, Container, Row } from "react-bootstrap"
import SectionTitle from "../../molecules/blocks/section-title"
import styled from "styled-components"
import ContactFormik from "../../molecules/contactForm"
import CircleSvg from "../../../images/Ellipse39.inline.svg";
import CircleSvg2 from "../../../images/Ellipse40.inline.svg";
import CircleSvg3 from "../../../images/Ellipse41.inline.svg";
import CircleSvg4 from "../../../images/Ellipse42.inline.svg";

const ContactForm = ({location}) => (
  <Wrapper id={`contact-form`}>
    <Container>
      <Row>
        <Col md={8} className={`ml-auto mr-auto`} style={{marginBottom: "120px"}}>
          <AbsoluteCircle className={`circle-1 d-none d-md-block`}>
              <CircleSvg />
          </AbsoluteCircle>
          <AbsoluteCircle className={`circle-2 d-none d-md-block`}>
            <CircleSvg2 />
          </AbsoluteCircle>
          <AbsoluteCircle className={`circle-3 d-none d-md-block`}>
            <CircleSvg3 />
          </AbsoluteCircle>
          <AbsoluteCircle className={`circle-4 d-none d-md-block`}>
            <CircleSvg4 />
          </AbsoluteCircle>
          <SectionTitle title={`Wypełnij formularz i zamów darmową, indywidualną ofertę używanego samochodu`} description={`Fair Play Cars`}/>
        </Col>
      </Row>
      <Row>
        <Col md={10} className={`ml-auto mr-auto`}>
          <ContactFormik location={location}/>
        </Col>
      </Row>
    </Container>
  </Wrapper>
);

const AbsoluteCircle = styled.div`
  position: absolute;
  
  &.circle-1{
    top: 80px;
    left: -55px;
    width: 44px;
  }
  
  &.circle-2{
    top: 35px;
    left: -120px;
    width: 29px;
  }
  
  &.circle-3{
    top: -35px;
    right: -75px;
    width: 122px;
  }
  
  &.circle-4{
    top: -60px;
    right: -15px;
    width: 44px;
  }
`;

const Wrapper = styled.section`
  margin-top: 280px;
`;

export default ContactForm;
