import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import SectionTitleWithText from "../../molecules/blocks/section-title-with-text"
import Image from "../../atoms/image"

const TeamSection = () => (
  <Wrapper id={`team`}>
    <Container>
      <Row>
        <Col md={5}>
          <Image fileName={`the-team`} />
        </Col>
        <Col md={6} className={`ml-auto`}>
          <SectionTitleWithText
            title={`Dzięki naszej wiedzy i doświadczeniu zakup auta będzie dla Ciebie przyjemnością`}
            description={`Nasz zespół`}
            text={`Fair Play Cars tworzą Grzegorz Garbaciak i Przemysław Bal - duet ekspertów w dziedzinie sprzedaży samochodów i ich finansowania. Ponad 15 lat doświadczenia na rynku pozwolił nam zbudować solidną bazę kooperujących z nami firm i doprowadzić procedurę weryfikacji i sprzedaży pojazdów do perfekcji. Dzięki temu nasza oferta samochodów używanych jest zawsze doskonale dopasowana do oczekiwań klienta, a proces sprzedaży czytelny, szybki i bezpieczny oraz możliwy do przeprowadzenia właściwie konieczności wychodzenia z domu. `}
          />
        </Col>
      </Row>
    </Container>
  </Wrapper>
)

const Wrapper = styled.section`
  margin-bottom: 280px;
`

export default TeamSection
