import React from "react";
import styled from "styled-components";

const Heading = ({size, children, ...rest}) => (
  <Wrapper as={`h${size}`} {...rest}>
      {children}
  </Wrapper>
);

const Wrapper = styled.div`
`
export default Heading;
