import React from "react"
import CircleWithIcon from "../../atoms/circle-with-icon"
import styled from "styled-components";

const LeftImageRightTextBlock = ({Icon, text}) => (
    <Wrapper>
      <CircleWithIcon Icon={Icon} />
      {text}
    </Wrapper>
);

const Wrapper = styled.div`
  background: #FFFFFF;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  margin-top: 7px;
  margin-bottom: 7px;
  padding: 18px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Lato, sans-serif;
  font-size: 18px;
  font-weight: bold;
`;

export default LeftImageRightTextBlock;
