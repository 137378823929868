import React from "react";
import ImageInsideCircle from "../../atoms/decorations/image-inside-circle"
import Heading from "../../atoms/texts/heading"
import Paragraph from "../../atoms/texts/paragraph"
import styled from "styled-components";

const ServiceBlock = ({SvgImage, title, description, topPadding}) => (
  <Wrapper topPadding={topPadding}>
      <StyledImageInsideCircle SvgImage={SvgImage} />
      <StyledHeading size={3}>{title}</StyledHeading>
      <Paragraph>{description}</Paragraph>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 60px;
  
  @media screen and (min-width: 992px) {
    margin-bottom: 0;
    padding-top: ${props => props.topPadding}px;
  }
`;

const StyledImageInsideCircle = styled(ImageInsideCircle)`
  margin-bottom: 38px;
`

const StyledHeading = styled(Heading)`
  margin-bottom: 28px;
`;

ServiceBlock.defaultProps = {
  topPadding: 0
}

export default ServiceBlock;
