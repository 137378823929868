import React from "react"
import { StaticQuery, graphql} from "gatsby"
import Img from "gatsby-image"

const ImageField = ({ fileName, alt }) => (
  <StaticQuery
    query={graphql`
        {
            allFile {
              edges {
                node {
                  id
                  relativePath
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            } 
        }
    `}
    render={data => {
      const image = data.allFile.edges.find(n => {
        return n.node.relativePath.includes(fileName)
      })

      if (!image) {
        return null
      }

      let imageFluid = ""

      if (image.node.childImageSharp !== null) {
        imageFluid = image.node.childImageSharp.fluid
      }

      if (imageFluid) {
        return <Img alt={alt} fluid={imageFluid}/>
      } else {
        return null
      }
    }}
  />
)

export default ImageField
