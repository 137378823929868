import React from "react"
import HeaderSection from "../organisms/index/header-section"
import ServiceSection from "../organisms/index/service-section"
import TeamSection from "../organisms/index/team-section"
import CarSliderSection from "../organisms/index/car-slider-section";
import ContactForm from "../organisms/forms/contact-form"
import ContactUs from "../organisms/index/contact-us"

const IndexTemplate = ({location}) => (
  <>
    <HeaderSection/>
    <ServiceSection/>
    <TeamSection/>
    <CarSliderSection/>
    <ContactForm location={location}/>
    <ContactUs/>
  </>
);

export default IndexTemplate;
