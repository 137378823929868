import React from "react"
import styled from "styled-components";
import Heading from "../../atoms/texts/heading"
import {colors} from "../../atoms/colors"

const SectionTitle = ({title, description}) => (
    <Wrapper>
        <Description>{description}</Description>
        <Heading size={2}>{title}</Heading>
    </Wrapper>
);

const Wrapper = styled.div`
  text-align: center;
`;

const Description = styled.div`
    color: ${colors.red};
    margin-bottom: 15px; 
`;

export default SectionTitle;
