import React from "react";
import styled from "styled-components";

const OrangeFormButton = ({text, ...rest}) => (
  <Button type={`submit`} {...rest}>
    {text}
  </Button>
);

const Button = styled.button`
  background: linear-gradient(90deg, #FAB501 5.31%, #FF6231 96.56%);
  border-radius: 25px;
  display: inline-block;
  width: 100%;
  padding: 13px 70px;
  color: white;
  font-family: Poppins, sans-serif;
  font-size: 16px;
  font-weight: 300;
  transition: all 0.4s ease-in-out;
  background-size: 150% 1px;
  border: 0;
  
  &:hover{
    background-position: -100px; 
    text-decoration: none;
    color: white;
  }
`

OrangeFormButton.defaultProps = {
  text: "Call to action"
}

export default OrangeFormButton;
