import React, { useCallback, useState, useRef, useEffect } from "react"
import { useFormik, Field } from "formik"
import { Col, Row } from "react-bootstrap"
import Input from "../atoms/form/input"
import SelectField from "../atoms/form/select"
import TextareaField from "../atoms/form/textarea"
import Paragraph from "../atoms/texts/paragraph"
import CheckboxField from "../atoms/form/checkbox"
import OrangeFormButton from "../atoms/buttons/orange-form-button"
import styled from "styled-components"
import { colors } from "../atoms/colors"
import * as Yup from "yup"
import ReCAPTCHA from "react-google-recaptcha"
import axios from "axios"

const ContactFormik = () => {

  const [checked, setChecked] = useState(false)
  const [error, setError] = useState();
  const [errorClass, setErrorClass] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [buttonText, setButtonText] = useState("Wyślij zapytanie");
  const [recatpchaValue, setRecaptchaValue] = useState()
  const rcRef = useRef(null);

  const handleChangeReCaptcha = useCallback((value) => {
    setRecaptchaValue(value)
  })

  const formik = useFormik({
    initialValues: {
      "bot-field": "",
      "form-name": "contactForm",
      carBrand: "",
      carModel: "",
      email: "",
      phone: "",
      carAdditionalInfo: "",
      carFuel: "",
      maxPrice: "",
      carYearFrom: "",
      carYearTo: "",
      carMileage: "",
      nameSurname: "",
      checkboxField: false,
      recaptcha: ""
    },
    validationSchema: Yup.object({
      carBrand: Yup.string()
        .min(2, "Nazwa marki jest za krótka")
        .max(30, "Nazwa marki jest za długa"),
      carModel: Yup.string()
        .min(2, "Nazwa marki jest za krótka")
        .max(30, "Nazwa marki jest za długa"),
      nameSurname: Yup.string()
        .min(2, "Imię i nazwisko jest za krótkie!")
        .max(50, "Imię i nazwisko jest za długie!")
        .required("Wymagane"),
      phone: Yup.string()
        .min(6, "Numer jest za krotki!")
        .max(15, "Numer jest za długi!")
        .required("Wymagane"),
      email: Yup.string().email("Nieprawidłowy adres e-mail").required("Wymagane"),
      checkboxField: Yup.boolean().oneOf([true], "Musisz zaakceptować postanowienie"),
      recaptcha: Yup.string().required("Weryfikacja jest wymagana").nullable(true),
    }),
    onSubmit: (values, {resetForm}) => {
      setButtonText("Wysyłanie...");
      setButtonDisabled(true);
      axios.post("/api/sendmail", values)
        .then(result => {
          if (result.data.result !== "success") {
            setButtonText('Wysyłanie nieudane.')
            setButtonDisabled(false);
            setError('Wysyłanie nie powiodło się');
            setErrorClass('text-error');
          } else {
            resetForm()
            setButtonText('Wyślij zapytanie.')
            setButtonDisabled(false);
            setError("Poprawnie wysłano zapytanie");
            setErrorClass('text-success');
          }
        })
        .catch((error) => {
          console.log(error.response.status)
        })
    }
  })

  return (
    <Wrapper id={`contact-form`} name={`contactForm`} data-netlify="true" data-netlify-recaptcha="true"
             data-netlify-honeypot="bot-field" onSubmit={formik.handleSubmit}>
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" />
      <WrapperInside>
        <Row>
          <Col md={6} lg={4}>
            <Input
              name={`carBrand`}
              placeholder={`Wpisz preferowaną markę lub marki`}
              label={`Marka samochodu*`}
              required
              value={formik.values.carBrand}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.carBrand}
            />
          </Col>
          <Col md={6} lg={4}>
            <Input
              name={`carModel`}
              placeholder={`Wpisz preferowany model lub modele`}
              label={`Model samochodu*`}
              required value={formik.values.carModel}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.carModel}
            />
          </Col>
          <Col md={6} lg={4}>
            <SelectField
              name={`carFuel`}
              placeholder={`Wybierz z listy`}
              label={`Rodzaj paliwa`}
              options={options.fuel}
              onChange={selectedOption => formik.setFieldValue("carFuel", selectedOption.value)}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.carFuel}
            />
          </Col>
          <Col md={6} lg={4}>
            <SelectField
              name={`maxPrice`}
              placeholder={`Cena do...`}
              label={`Cena maksymalna`}
              options={options.price}
              onChange={selectedOption => formik.setFieldValue("maxPrice", selectedOption.value)}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.maxPrice}
            />
          </Col>
          <Col md={6} lg={4}>
            <Row>
              <Col md={6}>
                <SelectField
                  name={`carYearFrom`}
                  placeholder={`od`}
                  label={`Rok produkcji`}
                  options={options.years}
                  onChange={selectedOption => formik.setFieldValue("carYearFrom", selectedOption.value)}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.errors.carYearFrom}
                />
              </Col>
              <Col md={6}>
                <SelectField
                  name={`carYearTo`}
                  placeholder={`do`}
                  options={options.years}
                  style={{ marginTop: "27px" }}
                  onChange={selectedOption => formik.setFieldValue("carYearTo", selectedOption.value)}
                  onBlur={formik.handleBlur}
                  errorMessage={formik.errors.carYearTo}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6} lg={4}>
            <SelectField
              name={`carMileage`}
              placeholder={`Do`}
              label={`Przebieg`}
              options={options.mileage}
              onChange={selectedOption => formik.setFieldValue("carMileage", selectedOption.value)}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.carMileage}
            />
          </Col>
          <Col md={6} lg={4}>
            <Input
              name={`nameSurname`}
              id={`name-surname`}
              placeholder={`Wpisz swoje dane`}
              label={`Imię i nazwisko*`}
              required value={formik.values.nameSurname}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.nameSurname}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.nameSurname}
            />
          </Col>
          <Col md={6} lg={4}>
            <Input
              name={`email`}
              id={`email`}
              placeholder={`Podaj e-mail kontaktowy`}
              label={`Adres e-mail*`}
              required
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.email}
            />
          </Col>
          <Col md={6} lg={4}>
            <Input
              name={`phone`}
              id={`phone`}
              placeholder={`Podaj telefon kontaktowy`}
              label={`Telefon*`}
              required
              value={formik.values.phone}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.phone}
            />
          </Col>
          <Col md={12}>
            <TextareaField
              style={{ marginBottom: "15px" }}
              name={`carAdditionalInfo`}
              placeholder={`Tu wpisz treść wiadomości, opisz czego oczekujesz od samochodu`}
              label={`Wiadomość (opcjonalnie)`}
              value={formik.values.carAdditionalInfo}
              onChange={formik.handleChange}/>
          </Col>
          <Col md={12}>
            <Paragraph style={{ fontSize: "9px" }}>* Pola z gwiazdką są obowiązkowe, bez nich nie będziemy mogli
              przygotować dla Ciebie oferty.</Paragraph>
          </Col>
          <Col md={12}>
            <CheckboxField
              name={`checkboxField`}
              checked={checked}
              onClick={() => {
                setChecked(!checked)
                formik.setFieldValue("checkboxField", !checked)
              }}
              text={`Potwierdzam zapoznanie się z obowiązkiem informacyjnym i wyrażam zgodę na przetwarzanie moich danych osobowych przez FPC Sp. j. w celu przygotowania i przesłania mi oferty zakupu samochodu  i treści marketingowych na podany adres e-mail.`}
              onBlur={formik.handleBlur}
              errorMessage={formik.errors.checkboxField}
              onChange={formik.handleChange}
            />
          </Col>
        </Row>
      </WrapperInside>

      <Row>
        <Col lg={5} md={5} sm={7} className={`ml-auto mr-auto mb-3`}>
          <CaptchaBox>
            <ReCAPTCHA
              sitekey={`6Leac_MZAAAAALFdK1UeAcoeNTyeR3BEO6meK0u3`}
              onChange={e => formik.setFieldValue("recaptcha", e)}
              hl={"pl"}
              ref={rcRef}
            />

            {formik.errors.recaptcha &&
              <p style={{textAlign: "center", color: "red", fontSize: "10px"}}>{formik.errors.recaptcha}</p>
            }
          </CaptchaBox>
        </Col>
      </Row>
      <Row>
        <Col lg={4} md={5} sm={7} className={`ml-auto mr-auto text-center`}>
          <OrangeFormButton text={buttonText} disabled={buttonDisabled}/>
          {error &&
            <p className={errorClass}>{error}</p>
          }
        </Col>
      </Row>

    </Wrapper>
  )
}

const options = {
  years: [
    { value: "2010", label: "2010" },
    { value: "2011", label: "2011" },
    { value: "2012", label: "2012" },
    { value: "2013", label: "2013" },
    { value: "2014", label: "2014" },
    { value: "2015", label: "2015" },
    { value: "2016", label: "2016" },
    { value: "2017", label: "2017" },
    { value: "2018", label: "2018" },
    { value: "2019", label: "2019" },
    { value: "2020", label: "2020" },
    { value: "2021", label: "2021" }
  ],
  fuel: [
    { value: "Diesel", label: "Diesel" },
    { value: "Benzyna", label: "Benzyna" },
    { value: "Gaz-LPG", label: "Gaz-LPG" },
    { value: "Hybryda", label: "Hybryda" },
    { value: "Elektryczny", label: "Elektryczny" }
  ],
  price: [
    { value: "25 000", label: "25 000" },
    { value: "50 000", label: "50 000" },
    { value: "75 000", label: "75 000" },
    { value: "100 000", label: "100 000" },
    { value: "125 000", label: "125 000" },
    { value: "150 000", label: "150 000" },
    { value: "175 000", label: "175 000" },
    { value: "200 000", label: "200 000" },
    { value: "> 200 000", label: "> 200 000" }
  ],
  mileage: [
    { value: "0", label: "0" },
    { value: "10 000", label: "10 000" },
    { value: "20 000", label: "20 000" },
    { value: "50 000", label: "50 000" },
    { value: "75 000", label: "75 000" },
    { value: "100 000", label: "100 000" },
    { value: "150 000", label: "150 000" },
    { value: "200 000", label: "200 000" },
    { value: "250 000", label: "250 000" }
  ]
}

const Wrapper = styled.form``

const WrapperInside = styled.div`
    padding: 40px;
    border-radius: 20px;
    border: 1px solid ${colors.orange};
    margin-bottom: 20px;
`

const CaptchaBox = styled.div`
  width: 100%;
  
  > div > div > div{
    margin: 0 auto;
  }
`

const Error = styled.div``

export default ContactFormik
