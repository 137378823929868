import React from "react"
import Heading from "../../atoms/texts/heading"
import Paragraph from "../../atoms/texts/paragraph"
import styled from "styled-components"
import OrangeButton from "../../atoms/buttons/orange-button"

const BlockWithAction = () => (
  <Wrapper>
    <Heading size={1}>
      Auta dla biznesu na zamówienie
    </Heading>
    <StyledParagraph>
      Potrzebujesz pewnego auta i kompleksowej obsługi? Doskonale trafiłeś - znajdziemy dla Ciebie idealny samochód, doradzimy, zaoferujemy finansowanie i ubezpieczenie, a nawet dostarczymy auto pod Twoją firmę lub dom.
    </StyledParagraph>
    <OrangeButton text={`Zapytaj o samochód`} to={`/#contact-form`} />
  </Wrapper>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
`

const StyledParagraph = styled(Paragraph)`
  margin-top: 40px;
  margin-bottom: 40px;
`;

export default BlockWithAction
