import React from "react"
import styled from "styled-components"
import Image from "../../atoms/image";

const HeaderImage = () => {

  return (
    <Wrapper>
      <CarImage>
        <Image fileName={`car-image-with-circles`} />
      </CarImage>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
`

const CarImage = styled.div`
  position: relative;
  z-index: 20;
`;

export default HeaderImage
