import React from "react"
import Heading from "../../atoms/texts/heading"
import Paragraph from "../../atoms/texts/paragraph"
import styled from "styled-components";
import ImageBackground from "../../atoms/image-background"
import { colors } from "../../atoms/colors"
import PriceInfo from "../../atoms/price-info"

const CarSlideItem = ({data}) => (
  <Wrapper>
    <CarImage>
      <ImageBg src={data.node.image.url} />
    </CarImage>

    <Title size={4}>{data.node.title}</Title>

    <CarAttributes>
      <Attribute>Rok produkcji: <span>{data.node.rokProdukcji}</span></Attribute>
      <Attribute>Moc: <span>{data.node.moc} KM</span></Attribute>
      <Attribute>Przebieg: <span>{data.node.przebieg}</span></Attribute>
      <Attribute>Pojemność silnika: <span>{data.node.pojemnoSilnika}</span></Attribute>
      <Attribute>Silnik: <span>{data.node.silnik}</span></Attribute>
    </CarAttributes>
  </Wrapper>
);

const ImageBg = styled.img`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  height: 230px;
`;

const Wrapper = styled.div`
  margin: 0 15px;
  overflow: hidden;
  background: #F8F8F8;
  box-shadow: 2px 2px 13px rgba(0,0,0,0.10);
  border-radius: 20px;
  margin-bottom: 30px;
  margin-top: 30px;
`;

const CarImage = styled.div``;

const Title = styled(Heading)`
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
`;

const CarAttributes = styled.div`
  padding: 25px;
  
  &:before{
    content: "";
    width: 50px;
    margin: 0 auto;
    height: 2px;
    background: linear-gradient(90deg, #FAB501 5.31%, #FF6231 96.56%);
    border-radius: 5px;
    display: block;
    margin-bottom: 27px;
  }
`;

const Attribute = styled(Paragraph)`
  text-align: center;
  border-bottom: 1px solid #E5E5E5;
  padding-bottom: 13px;
  font-family: Poppins, sans-serif;
  
  &:last-child{
    border-bottom: 0; 
  }
  
  span{
    color: ${colors.red}; 
  }
`;

export default CarSlideItem
