import React from "react"
import styled from "styled-components"

const ErrorMessage = ({ text }) => <Wrapper>{text}</Wrapper>;

export default ErrorMessage;

const Wrapper = styled.div`
  font-size: 10px;
  padding-top: 5px;
  padding-left: 10px;
  color: red;
  display: block;
`
