import React from "react"
import styled from "styled-components"
import ErrorMessage from "./error"

const Input = ({ name, placeholder, label, required, errorMessage, ...rest }) => (
  <Wrapper>
    <Label>{label}</Label>
    <InputWrapper name={name} placeholder={placeholder} required={required} {...rest}/>
    {errorMessage &&
      <ErrorMessage text={errorMessage}/>
    }
  </Wrapper>
)

const Wrapper = styled.div`
  margin-bottom: 35px;
`

const Error = styled.div``

const Label = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
`

const InputWrapper = styled.input`
  width: 100%;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  border: 0;
  padding: 10px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
`

export default Input
