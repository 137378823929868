import React from "react"
import styled from "styled-components"
import Heading from "../../atoms/texts/heading"
import { colors } from "../../atoms/colors"
import Paragraph from "../../atoms/texts/paragraph"

const SectionTitleWithText = ({title, description, text, styleParagraph}) => (
  <Wrapper>
    <Description>{description}</Description>
    <Heading size={2}>
      {title}
    </Heading>
    <StyledParagraph style={styleParagraph}>
      {text}
    </StyledParagraph>
  </Wrapper>
)

const Wrapper = styled.div`

`

const Description = styled.div`
    color: ${colors.red};
    margin-bottom: 15px;
`;

const StyledParagraph = styled(Paragraph)`
  margin-top: 40px;
`;

export default SectionTitleWithText
