import React from "react"
import styled, { css } from "styled-components"
import Paragraph from "../texts/paragraph"
import ErrorMessage from "./error"

const CheckboxField = ({ className, checked, text, onClick, errorMessage, value, ...props }) => (
  <>
    <CheckboxContainer className={className}>
      <div style={{ marginRight: "12px" }}>
        <HiddenCheckbox checked={checked} value={checked} {...props} />
        <StyledCheckbox checked={checked} onClick={onClick}>
          <Icon viewBox="0 0 14 14">
            <path
              d="M11.9574 0.820312L5.54258 9.27773L1.75 5.48789L0 7.23789L5.83242 13.0703L14 2.57031L11.9574 0.820312Z"
              fill="white"/>
          </Icon>
        </StyledCheckbox>
      </div>
      <Paragraph style={{ fontSize: "9px", lineHeight: "14px" }}>{text}</Paragraph>
    </CheckboxContainer>
    {errorMessage &&
    <ErrorMessage text={errorMessage}/>
    }
  </>
)

export default CheckboxField

const CheckboxContainer = styled.div`
  display: flex;
`

const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`

const StyledCheckbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: ${props => props.checked ? "linear-gradient(90deg, #FAB501 5.31%, #FF6231 96.56%)" : "#ddd"};
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  transition: all 150ms;
  
  &:hover{
    cursor: pointer;
  }
  
  ${props => props.checked ? checkedStyles : uncheckedStyles};
  
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
  }
`

const Icon = styled.svg`
  fill: none;  
  width: 14px;
  height: 14px;
`

const checkedStyles = css`
  
`
const uncheckedStyles = css`
  
`
