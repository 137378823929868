import React from "react";
import styled from "styled-components";
import { colors } from "./colors"

const PriceInfo = ({price, currency, additional}) => (
  <Wrapper>
    <span>{price} {currency}</span> {additional}
  </Wrapper>
);

const Wrapper = styled.div`
  width: fit-content;
  margin: 0 auto 22px;
  border-radius: 100px;
  padding: 8px 14px;
  border: 1px solid ${colors.red};
  
  span {
    font-weight: bold;
  }
`;

export default PriceInfo;
