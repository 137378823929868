import React from "react"
import styled from "styled-components"

const Paragraph = ({ children, ...rest}) => <StyledParagraph {...rest}>{children}</StyledParagraph>

const StyledParagraph = styled.p`
  font-family: Open Sans, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #252525;
`

export default Paragraph
