import React from "react";
import styled from "styled-components";
import { colors } from "../colors"

const ImageInsideCircle = ({SvgImage, ...rest}) => (
  <Wrapper {...rest}>
    <SvgImage />
  </Wrapper>
);

const Wrapper = styled.div`
  width: 65px;
  height: 65px;
  border: 9px solid ${colors.red};
  padding: 3px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export default ImageInsideCircle;
