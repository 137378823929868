import React from "react"
import styled from "styled-components"
import Select from "react-select"
import { colors } from "../colors"
import ErrorMessage from "../../atoms/form/error"

const SelectField = ({ name, placeholder, label, required, options, style, errorMessage, ...rest }) => (
  <Wrapper style={style}>
    {label &&
    <Label>{label}</Label>
    }
    <InputWrapper name={name} placeholder={placeholder} required={required} options={options}
                  styles={customStyles} {...rest}/>
    {errorMessage &&
    <ErrorMessage text={errorMessage}/>
    }
  </Wrapper>
)

const Wrapper = styled.div`
  margin-bottom: 35px;
`

const Error = styled.div``

const Label = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
`

const InputWrapper = styled(Select)`
  width: 100%;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  border: 0;
  font-family: Poppins, sans-serif;
  font-size: 12px;
`

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    borderColor: "transparent",
    borderRadius: "12px"
  }),
  dropdownIndicator: base => ({
    ...base,
    color: colors.red
  })
}

export default SelectField
