import React from "react"
import { Col, Container, Row } from "react-bootstrap"
import styled from "styled-components"
import SectionTitleWithText from "../../molecules/blocks/section-title-with-text"
import LeftImageRightTextBlock from "../../molecules/blocks/left-image-right-text-block"
import PhoneIcon from "../../../images/icons/bx_bxs-phone-call.inline.svg"
import EmailIcon from "../../../images/icons/mdi_email.inline.svg"
import { colors } from "../../atoms/colors"
import CircleSvg2 from "../../../images/Ellipse40.inline.svg"
import CircleSvg3 from "../../../images/Ellipse41.inline.svg"

const ContactUs = () => (
  <Wrapper id={`contact`}>
    <Container>
      <Relative>
        <DecorationBlock className={`d-none d-lg-block`}>
          <AbsoluteCircle className={`circle-1`}>
            <CircleSvg2 />
          </AbsoluteCircle>
          <AbsoluteCircle className={`circle-2`}>
            <CircleSvg3 />
          </AbsoluteCircle>
        </DecorationBlock>
        <Row>
          <Col md={12} lg={7}>
            <SectionTitleWithText styleParagraph={{ marginTop: 0 }}
                                  text={`Telefony odbieramy od poniedziałku do piątku w godzinach 8:00 - 18:00. `}
                                  description={`Kontakt z Fair Play Cars`}
                                  title={`Chcesz porozmawiać o ofercie samochodu dla Ciebie bezpośrednio? Zadzwoń lub napisz e-mail`}/>
          </Col>
          <Col md={12} lg={4} className={`d-flex justify-content-center flex-column`}>
            <LeftImageRightTextBlock Icon={PhoneIcon} text={`+48 609 261 774`}/>
            <LeftImageRightTextBlock Icon={EmailIcon} text={`biuro@fairplaycars.pl`}/>
          </Col>
        </Row>
      </Relative>
    </Container>
  </Wrapper>
)

const AbsoluteCircle = styled.div`
  position: absolute;
  
  &.circle-1{
    top: 15px;
    left: 158px;
    width: 29px;
  }
  
  &.circle-2{
    top: -45px;
    left: 63px;
    width: 65px;
  }
`;

const Wrapper = styled.section`
    margin-top: 300px;
    position: relative;
    margin-bottom: 200px;
`

const Relative = styled.div`
  position: relative;
`;

const DecorationBlock = styled.div`
  position: absolute;
  width: 100%;
  max-width: 648px;
  height: 100%;
  max-height: 210px; 
  border-radius: 20px;
  border: 1px solid ${colors.orange};
  right: 35px;
  top: calc(50% - 105px);
`

export default ContactUs
