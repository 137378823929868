import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import IndexTemplate from "../atomic/templates/indexTemplate"

const IndexPage = ({location}) => (
  <Layout>
    <SEO title="Home" />
    <IndexTemplate location={location}/>
  </Layout>
)

export default IndexPage
