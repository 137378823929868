import React from "react";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import SectionTitle from "../../molecules/blocks/section-title"
import ServiceBlock from "../../molecules/blocks/service-block"
import ServiceOneIcon from "../../../images/icons/codesandbox.inline.svg";
import ServiceTwoIcon from "../../../images/icons/award.inline.svg";
import ServiceThreeIcon from "../../../images/icons/slack.inline.svg";
import ServiceFourIcon from "../../../images/icons/la_apple-alt-solid.inline.svg";

const ServiceSection = () => (
    <Wrapper id={`services`}>
        <Container>
          <Row>
            <Col md={6} className={`ml-auto mr-auto`} style={{marginBottom: "120px"}}>
              <SectionTitle title={`Używane samochody dopasowane do Twoich potrzeb`} description={`Nasze usługi`}/>
            </Col>
          </Row>
          <Row>
            <Col md={6} lg={3}>
              <ServiceBlock
                SvgImage={ServiceOneIcon}
                description={`Prześlij nam swoje oczekiwania, a my znajdziemy samochód, który dokładnie spełnia Twoje kryteria. Dzięki sieci wiarygodnych partnerów możemy zaoferować Ci auto w konkurencyjnej cenie z pewną historią, udokumento- wanym przebiegiem i stanem technicznym. `}
                title={`Oferujemy tylko bezwypadkowe młode samochody z gwarancją przebiegu i udokumentowaną historią`}
                topPadding={65}
              />
            </Col>
            <Col md={6} lg={3}>
              <ServiceBlock
                SvgImage={ServiceTwoIcon}
                description={`Niezależnie od tego, czy szukasz samochodu osobowego, czy dostawczego, dla firmy czy też do użytku własnego, masz stosunkowo niewielki lub duży budżet - doradzimy i znajdziemy idealne auto dla Twoich potrzeb.`}
                title={`Realizujemy zamówienia zarówno dla firm, jak i klientów indywidualnych `}
                topPadding={0}
              />
            </Col>
            <Col md={6} lg={3}>
              <ServiceBlock
                SvgImage={ServiceThreeIcon}
                description={`Począwszy od oferty, którą przygotowujemy za darmo, przez proces decyzji i wyboru, aż po finalizację transakcji, rejestrację i przekazanie samochodu w Twoje ręcę - jesteśmy z Tobą na każdym kroku. Jeśli chcesz, możemy także zaoferować korzystne finansowanie, ubezpieczenie lub dostawę door-to-door.`}
                title={`Zapewniamy kompleksową obsługę sprzedażową także całkowicie online`}
                topPadding={29}
              />
            </Col>
            <Col md={6} lg={3}>
              <ServiceBlock
                SvgImage={ServiceFourIcon}
                description={`Dzięki współpracy z wiodącymi instytucjami finansowymi i ubezpieczeniowymi możemy zaoferować Ci rozwiązania dopasowane do Twoich potrzeb i możliwości. Realizujemy też wszystkie czynności w urzędach niezbędne do rejestracji samochodu.`}
                title={`Finansowanie, ubezpieczenie i rejestracja - wszystko w jednym miejscu`}
                topPadding={94}
              />
            </Col>
          </Row>
        </Container>
    </Wrapper>
);

const Wrapper = styled.section`
   margin-bottom: 280px;
`;

export default ServiceSection;
