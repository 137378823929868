import React from "react";
import styled from 'styled-components';

const TextareaField = ({name, placeholder, label, required, style, ...rest}) => (
  <Wrapper style={style}>
    <Label>{label}</Label>
    <InputWrapper name={name} placeholder={placeholder} required={required} rows={5} {...rest}/>
  </Wrapper>
);

const Wrapper = styled.div`
  margin-bottom: 35px;
`;

const Label = styled.div`
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.02em;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 10px;
`;

const InputWrapper = styled.textarea`
  width: 100%;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 6px 6px 16px rgba(0, 0, 0, 0.15);
  border: 0;
  padding: 10px;
  font-family: Poppins, sans-serif;
  font-size: 12px;
`;

export default TextareaField;
