import React from 'react'
import { graphql, StaticQuery } from 'gatsby'
import styled from 'styled-components'

import BackgroundImage from 'gatsby-background-image'

const BackgroundSection = ({fileName, className, children, height }) => (
  <StaticQuery
    query={graphql`
        {
            allFile {
              edges {
                node {
                  id
                  relativePath
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            } 
        }
    `}
    render={data => {
      const image = data.allFile.edges.find(n => {
        return n.node.relativePath.includes(fileName)
      })

      if (!image) {
        return null
      }

      let imageFluid = ""

      if (image.node.childImageSharp !== null) {
        imageFluid = image.node.childImageSharp.fluid
      }

      if (imageFluid) {
        return <BackgroundImage fluid={imageFluid} className={className} height={height}>{children}</BackgroundImage>
      } else {
        return null
      }
    }}
  />
)

const ImageBackground = styled(BackgroundSection)`
  width: 100%;
  background-position: bottom center;
  background-repeat: repeat-y;
  background-size: cover;
  height: ${props => props.height}px;
`

BackgroundSection.defaultProps = {
  height: "auto"
};

export default ImageBackground;
